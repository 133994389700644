import Button from "@mui/material/Button";

function ImagePreview(props) {
    const { previewImage , setPreviewImage} = props;
    const handleClearImage = () => {
        setPreviewImage(null)
    };
    return (
        previewImage ?
        <div>
            <img
                src={previewImage}
                alt="Preview"
                style={{ width: '100px', height: 'auto' }}
            />
            <Button variant="outlined" onClick={handleClearImage} style={{ marginLeft: '10px' }}>
                Clear Image
            </Button>
        </div> : ''
    );
}

export default ImagePreview;
