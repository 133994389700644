import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Link } from 'react-router-dom';
import { useMenuContext } from './Context';
import { useState, useEffect } from 'react';

function SideMenu() {
  const { checked } = useMenuContext();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(checked);
  }, [checked]);

  return (
    <div className={`sidebar ${!collapsed ? "sidebar-collapsed" : "sidebar-expanded"}`}>
        <MenuList>
          <MenuItem sx={{ height: '70px' }}component={Link} to="/category" >
            Category
          </MenuItem>
          <MenuItem sx={{ height: '70px' }}component={Link} to="/products">
            Products
          </MenuItem>
          <MenuItem sx={{ height: '70px' }}component={Link} to="/contact">
            Contact
          </MenuItem>
        </MenuList>
    </div>
  );
}

export default SideMenu;