import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Form.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ImagePreview from "./ImagePreview";

function FormP(props) {
  const { categories, product, onCancel, onEdit, onSubmit, editing } = props;

  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (product) {
      setProductName(product.name);
      setDescription(product.details);
      setCategory(product.category_id);
      if (product.image) {
        setPreviewImage(localStorage.getItem('url')+`/public/images/${product.image}`);
      }
    }
  }, [product]);

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (editing && product) {
      onEdit(product.id, productName, description, image, category);
    } else {
      onSubmit(productName , description, image , category);
    }
    setProductName('');
    setDescription('');
    setCategory('');
    setImage(null);
    setPreviewImage(null);
  };


  return (
    <div className="form-container">
      <h2>{editing ? 'Edit' : 'Add'}</h2>
      <form onSubmit={handleFormSubmit} className="form">
        <TextField
          id="product-name"
          label="Name"
          variant="standard"
          value={productName}
          onChange={handleProductNameChange}
        />

        <FormControl sx={{ m: 2, width: '15ch' }}>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category}
            label="Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="">
              <em>Select category</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem value={category.id} key={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          id="description"
          label="Description"
          multiline
          rows={2}
          variant="standard"
          value={description}
          onChange={handleDescriptionChange}
        />
        <br />
        <label htmlFor="upload-button">
          <input
            style={{ display: 'none' }}
            id="upload-button"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <Button variant="outlined" component="span">
            Upload Image
          </Button>
        </label>
        <ImagePreview previewImage={previewImage} setPreviewImage={setPreviewImage} />
        <br />
        <div className="buttons">
          <Button type="submit" variant="contained">
            {editing ? 'Save' : 'Add'}
          </Button>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FormP;
