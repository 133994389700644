import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function CollapsibleTable() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(localStorage.getItem('url') + '/api/getContacts')
      .then((response) => response.json())
      .then((data) => setData(data.data))
      .catch((error) => console.error(error));
  }, []);

  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
    const hours = dateTime.getUTCHours() + 3; // in db am ora pusa prost
    const minutes = dateTime.getUTCMinutes();
    return `${formattedDate} ${hours}:${minutes}`;
  }

  function Row({ row }) {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" sx={{ width: 'fit-content', textAlign: 'center' }}>
            {row.name}
          </TableCell>
          <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>{row.phone}</TableCell>
          <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>{row.email}</TableCell>
          <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>{formatDateTime(row.created_at)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography sx={{textAlign: 'center'}} variant="h6" gutterBottom component="div">
                  Message
                </Typography>
                <Typography>{row.message}</Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
  };

  return (
    <TableContainer component={Paper} sx={{ marginLeft: '30px', marginRight: '30px', maxWidth: 'calc(100% - 60px)', textAlign: 'center' }}>
    <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ width: 'fit-content', textAlign: 'center' }}>Name</TableCell>
            <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>Phone</TableCell>
            <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>Email</TableCell>
            <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
