import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Form.css';
import ImagePreview from './ImagePreview';

function FormC(props) {
  const { selectedCategory, onCancel, onSubmit, onEdit, editing } = props;

  const [categoryName, setCategoryName] = useState('');
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  

  useEffect(() => {
    if (selectedCategory) {
      setCategoryName(selectedCategory.name);
      if (selectedCategory.image) {
        setPreviewImage(localStorage.getItem('url')+`/public/images/${selectedCategory.image}`);
      }
    }
  }, [selectedCategory]);

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };


  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (editing && selectedCategory) {
      onEdit(selectedCategory.id, categoryName, image);
    } else {
      onSubmit(categoryName, image);
    }
    setCategoryName('');
    setImage(null);
    setPreviewImage(null);
  };
  
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  return (
    <div className="form-container">
      <h2>{editing ? 'Save' : 'Add'}</h2>
      <form onSubmit={handleFormSubmit} className="form">
        <TextField
          id="category-name"
          label="Name"
          variant="standard"
          value={categoryName}
          onChange={handleCategoryNameChange}
        />

        <br />
        <label htmlFor="upload-button">
          <input
            style={{ display: 'none' }}
            id="upload-button"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <Button variant="outlined" component="span">
            Upload Image
          </Button>
        </label>
        <ImagePreview previewImage={previewImage} setPreviewImage={setPreviewImage} />
        <br />
        <div className="buttons">
          <Button type="submit" variant="contained">
            {editing ? 'Save' : 'Add'}
          </Button>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FormC;
