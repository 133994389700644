// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  const handleLogin = async () => {
    // Realizăm cererea API pentru autentificare
    try {
      const response = await fetch(localStorage.getItem('url')+'/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: username,
          password: password,
        }),
        
      });

      const data = await response.json();

      if (data.status === true) {
        localStorage.setItem('isLoggedIn', 'true');
        onLogin(true);
        navigate('/category');
      } else {
        alert('User or password incorrect');
      }
    } catch (error) {
      console.log(error);
      // Tratează erorile de cerere sau de răspuns aici
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <div className="login">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}> 
        <div className="">
          <TextField
            id="outlined-basic"
            label="User"
            variant="outlined"
            sx={{ m: 2 }}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            sx={{ m: 2 }}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Button variant="outlined" type="submit">
            Login
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default Login;