// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Banner from './components/Banner';
import Login from './Pages/Login';
import Category from './Pages/Category';
import Products from './Pages/Products';
import Contact from './Pages/Contact';
import SidebarMenu from './components/SideMenu';
import './App.css';
import { MenuProvider } from './components/Context';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  useEffect(() => {
    const storedLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedLoggedIn === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <MenuProvider>
      <div className="app">
        {isLoggedIn && (
          <div>
            <Banner handleLogout={handleLogout} />
            <div className="flex">
              <SidebarMenu />
              <div className="content">
                <Routes>
                  <Route path="/" element={<Navigate to="/category" replace={true} />} />
                  <Route path="/category" element={<Category />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/contact" element={<Contact />} />
                </Routes>
              </div>
            </div>
          </div>
        )}
        {!isLoggedIn && (
          <div className="login">
            <Login onLogin={handleLogin} />
          </div>
        )}
      </div>
    </MenuProvider>
  );
}

export default App;
