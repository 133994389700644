import React from 'react';
import { Card } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import WarningDialog from './WarningDialog'
import {render} from "@testing-library/react";
function ItemList(props) {
  const { items, onEditClick , type , fetchData } = props;


  const fetchDelete = (id) => {
    if (type === 'product')fetchDeleteProduct(id)
    if (type === 'category')fetchDeleteCategory(id)
  }
  const fetchDeleteProduct = async (id) => {
    const params = new FormData();
    params.append('id', id);
    const options = {
      method: 'POST',
      body: params
    };

    try {
      const response = await fetch(localStorage.getItem('url') + '/api/deleteProduct', options);
      const data = await response.json();
      if (data.status) {
        fetchData()
      } else {
        console.log('nu merge boss');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchDeleteCategory = async (id) => {
    const params = new FormData();
    params.append('id', id);

    const options = {
      method: 'POST',
      body: params
    };

    try {
      const response = await fetch(localStorage.getItem('url') + '/api/deleteCategory', options);
      const data = await response.json();
      if (data.status) {
        fetchData()
      } else {
        console.log('nu merge boss');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const confirmDeleteAction = (id)=> {
    render(<WarningDialog deleteItem={() => fetchDelete(id)} /> )
  }
  if (!items) {
    return <p>Loading...</p>;
  }

  if (items.length === 0) {
    return <p>No items found.</p>;
  }

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '50px' }}>
      {items.map((item) => (
        <Card key={item.id}>
          <img
            src={localStorage.getItem('url') + `/public/images/${item.image}`}
            alt={item.name}
            className="product-image"
            style={{ maxWidth: '200px', width: '200px', maxHeight: '150px', height: '150px' }}
          />
          <br />
          <label style={{ alignSelf: 'center' }}>{item.name}</label>
          <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton aria-label="edit" size="small" onClick={() => onEditClick(item)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" size="small" onClick={() => confirmDeleteAction(item.id) }>
              <DeleteIcon />
            </IconButton>
          </CardActions>
        </Card>
      ))}
    </div>
  );
}

export default ItemList;
