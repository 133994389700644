import React, { createContext, useState, useContext } from 'react';

const MenuContext = createContext();

export function useMenuContext() {
  return useContext(MenuContext);
}

export function MenuProvider({ children }) {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <MenuContext.Provider value={{ checked, handleChange }}>
      {children}
    </MenuContext.Provider>
  );
}
