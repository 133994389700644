import React, { useState, useEffect } from 'react';
import './StylePages.css';
import { Button, Stack, TextField } from '@mui/material';
import ItemList from '../components/ItemList';
import FormP from '../components/FormP';

function Products() {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState([]);
  const [editing, setEditing] = useState(false);
  const [searched, setSearched] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(localStorage.getItem('url') + '/api/getCategories');
      const data = await response.json();
      setCategories(data.data || []);
    } catch (error) {
      console.log('A apărut o eroare:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(localStorage.getItem('url') + '/api/getAllProducts');
      const data = await response.json();
      setProducts(data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchAddProduct = async (productName, description, image, category) => {
    const params = new FormData();
    params.append('image', image);
    params.append('name', productName);
    params.append('details', description);
    params.append('category_id', category);

    const options = {
      method: 'POST',
      body: params
    };

    try {
      const response = await fetch(localStorage.getItem('url') + '/api/addProduct', options);
      const data = await response.json();
      if (data.status) {
        fetchProducts();
        setEditing(false);
        return true;
      } else {
        console.log('nu merge boss');
        return false;
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      return false;
    }
  };


  const fetchEditProduct = async (productId, productName, description, image, category) => {
    const params = new FormData();
    params.append('id', productId);
    if (image) params.append('image', image);
    params.append('name', productName);
    params.append('details', description);
    params.append('category_id', category);
    const options = {
      method: 'POST',
      body: params
    };

    try {
      const response = await fetch(
        localStorage.getItem('url') + `/api/editProduct`, options);
      const data = await response.json();
      if (data.status) {
        fetchProducts();

        setEditing(false);
        setSelectedProduct(null);
        return true;
      } else {
        console.log('nu merge boss');
        return false;
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      return false;
    }
  };

  const handleEditClick = (product) => {
    setEditing(true);
    setSelectedProduct(product);
  };



  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    if (searchTerm) {
      const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilter(filteredProducts);
      setSearched(true);
    }
  };

  const handleClearSearch = () => {
    setFilter([]);
    setSearched(false);
    setSearchTerm('');
  };
  const handleCancelEdit = () => {
    setEditing(false);
    setSelectedProduct(null);
  };

  return (
    <div>
      <div className="products">
        {editing ? (
          <FormP
            categories={categories}
            product={selectedProduct}
            editing={editing}
            onSubmit={fetchAddProduct}
            onEdit={fetchEditProduct}
            onCancel={handleCancelEdit}
          />
        ) : (
          <div className="product-list">
          <div className="GrupButton">
            <div className='search-bar'>
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
              />
              <div className="buttons" style={{width:'100%'}}>
                <Stack direction="row" spacing={3}>
                  <Button variant="contained" onClick={handleSearchClick}>
                    Search
                  </Button>
                  <Button variant="contained" onClick={handleClearSearch}>
                    Clear
                  </Button>
                </Stack>
                <Button style={{float:"right",marginLeft: '24px'}}variant="contained" onClick={() => setEditing(true)}>
                Add 
              </Button>
              </div>
            </div>
          </div>


            <br />
            {!searched ? (
              <ItemList items={products} onEditClick={handleEditClick} type={'product'} fetchData={fetchProducts} />
            ) : (
              <ItemList items={filter} onEditClick={handleEditClick} type={'product'} fetchData={fetchAddProduct} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Products;
