import React, { useState, useEffect } from 'react';
import './StylePages.css';
import Button from '@mui/material/Button';
import ItemList from '../components/ItemList';
import FormC from '../components/FormC';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

function Category() {
  const [categories, setCategories] = useState([]);
  const [editing, setEditing] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState([]);
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(localStorage.getItem('url') + '/api/getCategories');
      const data = await response.json();
      setCategories(data.data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchAddCategory = async (categoryName, image) => {
    const params = new FormData();
    params.append('image', image);
    params.append('name', categoryName);

    const options = {
      method: 'POST',
      body: params,
    };

    try {
      const response = await fetch(localStorage.getItem('url') + '/api/addCategory', options);
      const data = await response.json();
      if (data.status) {
        fetchCategories();
        setEditing(false);
        return true;
      } else {
        console.log('nu merge boss');
        return false;
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      return false;
    }
  };

  const fetchEditCategory = async (categoryId, categoryName, image) => {
    const params = new FormData();
    params.append('id', categoryId);
    if(image)params.append('image', image);
    params.append('name', categoryName);

    const options = {
      method: 'POST',
      body: params,
    };

    try {
      const response = await fetch(
        localStorage.getItem('url') + `/api/editCategory`, options
      );
      const data = await response.json();
      if (data.status) {
        fetchCategories();
        // const updatedCategories = categories.map((category) => {
        //   if (category.id === updatedCategory.id) {
        //     return updatedCategory;
        //   }
        //   return category;
        // });
        // setCategories(updatedCategories);
        setEditing(false);
        setSelectedCategory(null);
        return true;
      } else {
        console.log('nu merge boss');
        return false;
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      return false;
    }
  };

  const handleAddCategory = () => {
    setEditing(true);
    setSelectedCategory(null);
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setSelectedCategory(null);
  };

  const handleSave = () => {
    setEditing(false);
    setSelectedCategory(null);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setEditing(true);
  };

  const handleSearch = () => {
    const filteredCategories = categories.filter((category) =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilter(filteredCategories);
    searchTerm ? setSearched(true) : setSearched(false);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearched(false);
  };

  return (
    <div className="category">
      {editing ? (
        <FormC
          onSubmit={fetchAddCategory}
          onEdit={fetchEditCategory}
          onCancel={handleCancelEdit}
          onSave={handleSave}
          editing={editing}
          selectedCategory={selectedCategory}
          categories={categories}
        />
      ) : (
        <div className="product-list">
          <div className="d-flex align-items-start">
            <div className="search-bar">
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="buttons" style={{width:'100%'}}>
                <Stack direction="row" spacing={3} >
                  <Button variant="contained" onClick={handleSearch}>
                    Search
                  </Button>
                  <Button variant="contained" onClick={handleClearSearch}>
                    Clear
                  </Button>
               
                </Stack>
                <Button style={{float:"right",marginLeft: '24px'}} variant="contained" onClick={handleAddCategory}>
                    Add
                  </Button>
              </div>
            </div>
          
          </div>
          <br />
        </div>
      )}

      {!editing ? <ItemList items={!searched ? categories : filter} onEditClick={handleEditCategory} type={'category'} fetchData={fetchCategories} /> : null}
    </div>
  );
}

export default Category;
